import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2443ed68"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "global-body"
};
import { ref, onMounted } from 'vue';
import sendOrders from './sendOrders/sendOrders'; // 服务站业绩

import ServiceStation from './ServiceStation/ServiceStation.vue'; // 派单员业绩

import worker from './worker/worker.vue';
import { getstoreList } from '@/api/general';
export default {
  __name: 'index',

  setup(__props) {
    const generateFrom = () => {
      return {
        storeId: '',
        masterWorkerId: '',
        workerId: '',
        start_datetime: '',
        end_datetime: ''
      };
    };

    const formModel = ref(generateFrom());
    const serviceStationRef = ref();
    const sendOrdersRef = ref();
    const workerRef = ref();
    const datePickerDisabled = ref(false); // 下单时间的禁用

    const PDYlistData = [{
      label: '16',
      value: 16
    }]; // 师傅列表内容

    const workerlistData = [{
      label: '15',
      value: 15
    }];
    const activeName = ref('服务站业绩'); // 服务站chang事件

    const serveChange = e => {
      formModel.value.storeId = e[1];
    };

    const handleClick = e => {
      activeName.value = e.props.name;

      if (activeName.value === '服务站业绩') {
        setTimeout(() => {
          serviceStationRef.value.showModal({
            storeId: formModel.value.storeId,
            start_datetime: formModel.value.start_datetime,
            end_datetime: formModel.value.end_datetime
          });
        }, 100);
        datePickerDisabled.value = false;
      } else if (activeName.value === '派单员业绩') {
        setTimeout(() => {
          sendOrdersRef.value.showModal({
            workerId: formModel.value.workerId,
            start_datetime: formModel.value.start_datetime,
            end_datetime: formModel.value.end_datetime
          });
        }, 100);
        datePickerDisabled.value = true;
      } else if (activeName.value === '上门师傅业绩') {
        setTimeout(() => {
          workerRef.value.showModal({
            workerId: formModel.value.masterWorkerId,
            start_datetime: formModel.value.start_datetime,
            end_datetime: formModel.value.end_datetime
          });
        }, 100);
        datePickerDisabled.value = false;
      }
    };

    const StorelistData = ref([]); // 获取服务站列表数据

    const getStoreData = async () => {
      try {
        const resData = await getstoreList();

        if (resData.status === 'success') {
          StorelistData.value = resData.data;
        } else {
          console.log('获取服务站数据失败了。。。');
        }
      } catch (err) {
        console.log(err);
      }
    };

    const dateArr = ref();

    const changeDate = dateArr => {
      if (dateArr && dateArr.length > 0) {
        formModel.value.start_datetime = dateArr[0];
        formModel.value.end_datetime = dateArr[1];
      } else {
        formModel.value.start_datetime = '';
        formModel.value.end_datetime = '';
      }
    }; // 派单员id列表


    const PDYChange = e => {
      formModel.value.workerId = e[0];
    }; // 上门师傅选择


    const masterChange = e => {
      formModel.value.masterWorkerId = e[0];
    }; // 判断 如果当前登录用户信息是派单员时 禁用派单员的下拉框


    const workerCascaderDisabled = ref(false);

    const workerCascader = () => {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));

      if (userInfo.job === '派单员') {
        formModel.value.workerId = userInfo.workerId;
      }

      workerCascaderDisabled.value = userInfo.job === '派单员';
    }; // 派单员中间的tab点击


    const PDYtab = tabvalue => {
      console.log('tab点击');
      console.log(tabvalue);

      if (tabvalue === '0') {
        datePickerDisabled.value = true;
      } else {
        datePickerDisabled.value = false;
      }
    }; // 搜索


    const onSubmit = () => {
      if (activeName.value === '服务站业绩') {
        serviceStationRef.value.showModal({
          storeId: formModel.value.storeId,
          start_datetime: formModel.value.start_datetime,
          end_datetime: formModel.value.end_datetime
        });
      } else if (activeName.value === '派单员业绩') {
        sendOrdersRef.value.showModal({
          workerId: formModel.value.workerId,
          start_datetime: formModel.value.start_datetime,
          end_datetime: formModel.value.end_datetime
        });
      } else if (activeName.value === '上门师傅业绩') {
        workerRef.value.showModal({
          workerId: formModel.value.masterWorkerId,
          start_datetime: formModel.value.start_datetime,
          end_datetime: formModel.value.end_datetime
        });
      }
    }; // 重置


    const restSubmit = () => {
      formModel.value = generateFrom();
      dateArr.value = '';

      if (activeName.value === '服务站业绩') {
        serviceStationRef.value.showModal();
      } else if (activeName.value === '派单员业绩') {
        sendOrdersRef.value.showModal();
      } else if (activeName.value === '上门师傅业绩') {
        workerRef.value.showModal();
      }
    }; // 初始化


    const init = async () => {
      await getStoreData();
      workerCascader();
    };

    onMounted(() => {
      init();
    });
    return (_ctx, _cache) => {
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");

      const _component_el_tabs = _resolveComponent("el-tabs");

      const _component_el_cascader = _resolveComponent("el-cascader");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_form = _resolveComponent("el-form");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_tabs, {
        modelValue: activeName.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => activeName.value = $event),
        class: "demo-tabs",
        onTabClick: handleClick
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
          label: "服务站业绩",
          name: "服务站业绩"
        }), _createVNode(_component_el_tab_pane, {
          label: "派单员业绩",
          name: "派单员业绩"
        }), _createVNode(_component_el_tab_pane, {
          label: "上门师傅业绩",
          name: "上门师傅业绩"
        })]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_el_form, {
        inline: true,
        model: formModel.value,
        class: "global-search"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_row, {
          gutter: 20
        }, {
          default: _withCtx(() => [activeName.value === '服务站业绩' ? (_openBlock(), _createBlock(_component_el_col, {
            key: 0,
            span: 4
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "服务站",
              style: {
                "width": "300px"
              }
            }, {
              default: _withCtx(() => [_createVNode(_component_el_cascader, {
                modelValue: formModel.value.storeId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.value.storeId = $event),
                options: StorelistData.value,
                onChange: serveChange,
                clearable: ""
              }, null, 8, ["modelValue", "options"])]),
              _: 1
            })]),
            _: 1
          })) : _createCommentVNode("", true), activeName.value === '派单员业绩' ? (_openBlock(), _createBlock(_component_el_col, {
            key: 1,
            span: 4
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "派单员ID",
              style: {
                "width": "300px"
              }
            }, {
              default: _withCtx(() => [_createVNode(_component_el_cascader, {
                modelValue: formModel.value.workerId,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formModel.value.workerId = $event),
                options: PDYlistData,
                onChange: PDYChange,
                clearable: "",
                disabled: workerCascaderDisabled.value
              }, null, 8, ["modelValue", "disabled"])]),
              _: 1
            })]),
            _: 1
          })) : _createCommentVNode("", true), activeName.value === '上门师傅业绩' ? (_openBlock(), _createBlock(_component_el_col, {
            key: 2,
            span: 4
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "上门师傅ID",
              style: {
                "width": "300px"
              }
            }, {
              default: _withCtx(() => [_createVNode(_component_el_cascader, {
                modelValue: formModel.value.masterWorkerId,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formModel.value.masterWorkerId = $event),
                options: workerlistData,
                onChange: masterChange,
                clearable: ""
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          })) : _createCommentVNode("", true), _createVNode(_component_el_col, {
            span: 4
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "下单时间"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_date_picker, {
                modelValue: dateArr.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => dateArr.value = $event),
                type: "daterange",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                format: "YYYY-MM-DD",
                "value-format": "YYYY-MM-DD HH:mm:ss",
                "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)],
                onChange: changeDate,
                disabled: datePickerDisabled.value
              }, null, 8, ["modelValue", "default-time", "disabled"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 4
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_el_button, {
                type: "primary",
                onClick: onSubmit
              }, {
                default: _withCtx(() => [_createTextVNode("确认")]),
                _: 1
              }), _createVNode(_component_el_button, {
                onClick: restSubmit
              }, {
                default: _withCtx(() => [_createTextVNode("重置")]),
                _: 1
              })])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), activeName.value === '服务站业绩' ? (_openBlock(), _createBlock(ServiceStation, {
        key: 0,
        ref_key: "serviceStationRef",
        ref: serviceStationRef
      }, null, 512)) : _createCommentVNode("", true), activeName.value === '派单员业绩' ? (_openBlock(), _createBlock(_unref(sendOrders), {
        key: 1,
        ref_key: "sendOrdersRef",
        ref: sendOrdersRef,
        onPDYtab: PDYtab
      }, null, 512)) : _createCommentVNode("", true), activeName.value === '上门师傅业绩' ? (_openBlock(), _createBlock(worker, {
        key: 2,
        ref_key: "workerRef",
        ref: workerRef
      }, null, 512)) : _createCommentVNode("", true)]);
    };
  }

};